//maybe put in the form control itself
//better grid navigation
//border selected elements with something
//add clickability to users, give more information
//make sure excluding other users and captain in the adding

import React, { useState, useEffect } from 'react';
import { FormControl, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { stringToColor } from './CriticalFunctions';
import axios from 'axios';
import { myAxiosInstance } from './axiosConfig';
import { formatGraduationDate } from './CriticalFunctions';
import { useAuth } from './AuthContext';
import { StyledFormControl, StyledFormLabel, StyledFormGroup } from './StyledBootstrap';
import { useTheme } from 'styled-components';



const bubbleStyle = (color) => ({
  display: 'inline-block',
  padding: '5px 10px',
  margin: '5px',
  borderRadius: '15px',
  backgroundColor: color,
  color: 'black',
  fontSize: '0.8rem',
  cursor: 'pointer',
});

const closeButtonStyle = {
  marginLeft: '0.3rem',
  cursor: 'pointer'
};

const suggestionListStyle = {
  listStyleType: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  flexWrap: 'wrap'
};






function MultiSelectUsers({ otherUsers, value, onChange, label, endpoint }) {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedItems, setSelectedItems] = useState(
    value.map(item => ({ ...item, color: stringToColor(item.first_name + " " + item.last_name) }))
  );

  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);


  const themeMode = useTheme();
  const { userSlug } = useAuth();

  const fetchOptions = async (query) => {
    try {
      if (endpoint)
      {
        const response = await myAxiosInstance.get('/api/search-users/' + '?q=' + query);
        return response.data.users;
      }
      
      return otherUsers.filter(item =>
        (item.first_name + " " + item.last_name).toLowerCase().startsWith(query.toLowerCase())

      );


    } catch (error) {
      return [];
    }
  };



  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (inputValue) {

        fetchOptions(inputValue).then(data => {
          const filteredData = data.filter(item =>
            !selectedItems.some(selectedItem => selectedItem.slug === item.slug) //check to make sure
          ).filter(item =>
            (!endpoint || (!otherUsers.some(otherUser => otherUser.slug === item.slug) && !(item.slug===userSlug)))
          );
          setSuggestions(filteredData);
        });
      } else {
        setSuggestions([]);
      }
    }, 100); // 300ms debounce time

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [inputValue]);



  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setActiveSuggestionIndex(0);
  };

  const handleSelect = (item) => {
    if (!selectedItems.some(selectedItem => selectedItem.slug === item.slug)) { //possibly change
      const updatedItems = [...selectedItems, { ...item, color: stringToColor(item.first_name + " " + item.last_name) }];
      setSelectedItems(updatedItems);
      setInputValue('');
      setActiveSuggestionIndex(0);
      onChange(updatedItems.map(item => {
        const { color, ...rest } = item;
        return rest;
    }));
    }
  };


  const handleRemoveItem = (slug) => {
    const updatedItems = selectedItems.filter(item => item.slug !== slug);
    setSelectedItems(updatedItems);
    onChange(updatedItems.map(item => {
        const { color, ...rest } = item;
        return rest;
    }));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (suggestions.length > 0)
      {
        handleSelect(suggestions[activeSuggestionIndex]);
      }
      
    } else if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
      setActiveSuggestionIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
    } else if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
      setActiveSuggestionIndex((prevIndex) => (prevIndex + suggestions.length - 1) % suggestions.length);
    }
  };

  const openUser = (slug) => {
    // Replace '/new-route' with your desired path
    // Use your application's base URL if it's different from the current origin
    const newTabUrl = window.location.origin + '/dashboard/user/' + slug + '/';
    window.open(newTabUrl, '_blank');
};

  return (
    <StyledFormGroup className='mb-3'>
      <StyledFormLabel>{label}</StyledFormLabel>
      <div>

        <StyledFormControl
          type="text"
          value={inputValue}
          placeholder={"Search Users"}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        {suggestions.length > 0 && (
          <ul style={suggestionListStyle}>
            {suggestions.map((item, index) => (
              <li key={index}
                style={{
                  display: 'flex', // Using flex to center the content
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  marginBottom: '5px',
                }}
                onClick={() => handleSelect(item)}
              >
                <span
                  style={{
                    ...bubbleStyle(stringToColor(item.first_name + " " + item.last_name)),
                    cursor: 'pointer',
                    border: index === activeSuggestionIndex ? `2px solid ${themeMode.onSurface}` : 'none',
                    boxShadow: index === activeSuggestionIndex ? '0px 0px 10px rgba(0, 0, 0, 0.2)' : 'none',
                  }}
                >
                  {item.first_name + " " + item.last_name + " '" + formatGraduationDate(item.graduation_date) + ", "  + item.university + ", " + item.email}
                </span>
              </li>
            ))}
          </ul>


        )}
        <div>
          {selectedItems.map((item, index) => (
            <span key={index} style={bubbleStyle(item.color)} onClick={() => openUser(item.slug)}>
              {item.first_name + " " + item.last_name + " '" + formatGraduationDate(item.graduation_date) +  ", " +  item.university + ", " + item.email}
              <span
                style={closeButtonStyle}
                onClick={(e) => {
                  handleRemoveItem(item.slug);
                  e.stopPropagation();
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </span>
          ))}
        </div>

      </div>
    </StyledFormGroup>
  );
}

export default MultiSelectUsers;