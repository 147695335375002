import Spinner from 'react-bootstrap/Spinner';
import { Row, Container } from 'react-bootstrap';
import { useTheme } from 'styled-components';
import logo_gradient from './Images/logo_gradient.png';
import logo_white from './Images/logo_white.png';
import logo_black from './Images/logo_black.png';
import logo_navy from './Images/logo_navy.png';


function MainLoading() {

    const themeMode = useTheme();
    return (
        <Container fluid style={{ height: '100vh', backgroundColor: themeMode.background}} className='d-flex flex-column justify-content-center'>
        <Row className='justify-content-center text-center'>
            <div >
            <img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="40rem"/>

                <Spinner animation="grow"></Spinner>
            </div>
        </Row>
        </Container>
        
    );
}


function Loading() {

    return (
        
        <Row className='justify-content-center text-center'>
            <div >
                <Spinner animation="grow"></Spinner>
            </div>
        </Row>
        
    );
}

export default Loading;
export {MainLoading};
