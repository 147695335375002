import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FormInput } from './FormComponents';
import MultiSelect from './MultiSelect';
import SingleSelect from './SingleSelect';
import UserProfile from './UserProfile';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { StyledForm, StyledFormGroup, StyledFormControl, StyledFormLabel, StyledFormSelect, StyledTextArea } from './StyledBootstrap';
import CustomModal from './CustomModal';
import { apiP } from './CriticalFunctions';
import ProfilePictureUpload from './ProfilePictureUpload';


function Edit({ modalTitle, bottomReload, setBottomReload, show, setShow, modalMessage, setModalMessage, handleClose, reload, setReload, modalEditData, setModalEditData }) {


  const navigate = useNavigate();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setModalEditData({ ...modalEditData, [name]: value });
  };

  const handleSaveChanges = async (e) => {
    let data = {
      ...modalEditData,
      university: modalEditData.university.length === 0 ? '' : modalEditData.university[0], // Spread operator to copy all properties of userInfo,
      company: modalEditData.company.length === 0 ? '' : modalEditData.company[0]
    };

    const response = await apiP(data, "update-user-profile/", setModalMessage, "Done!", null, null, true, bottomReload, setBottomReload, "put");
    if (response && (modalTitle === 'Edit Name')) {
      navigate('/dashboard/user/' + response.data.slug + '/');
      setReload(reload + 1);

    }
  };



  return (
    <>
      <CustomModal title={"Update Profile"} actionText={"Save Changes"} actionFunction={handleSaveChanges} show={show} setShow={setShow} message={modalMessage} setMessage={setModalMessage} handleClose={handleClose}>
        <StyledForm>

          
            <>
              <StyledFormGroup controlId="formInput">
                <StyledFormLabel>First Name</StyledFormLabel>
                <StyledFormControl
                  type="text"
                  name="first_name"
                  value={modalEditData.first_name}
                  onChange={handleInputChange}
                />
              </StyledFormGroup>
              <StyledFormGroup controlId="formInput">
                <StyledFormLabel>Last Name</StyledFormLabel>
                <StyledFormControl
                  type="text"
                  name="last_name"
                  value={modalEditData.last_name}
                  onChange={handleInputChange}
                />
              </StyledFormGroup>
            </>
            
            <ProfilePictureUpload />


          
          <SingleSelect
            endpoint='search-universities'
            value={modalEditData.university}
            onChange={(selected) => {
              setModalEditData({ ...modalEditData, university: selected })
            }}
            label="University"
          />

          
            <MultiSelect
              endpoint='search-degrees'
              value={modalEditData.major_or_grad_program}
              onChange={(selected) => {
                setModalEditData({ ...modalEditData, major_or_grad_program: selected })
              }}
              label="Program(s) of Study"
            />
            <SingleSelect
            endpoint='search-companies'
            value={modalEditData.company}
            onChange={(selected) => {
              setModalEditData({ ...modalEditData, company: selected })
            }}
            label="Highlighted Work Experience"
          />



          <StyledFormGroup>
            <StyledFormLabel>Graduation Date</StyledFormLabel>
            <StyledFormControl
              type="date"
              name="graduation_date"
              value={modalEditData.graduation_date}
              onChange={handleInputChange}
            />
          </StyledFormGroup>

          
            <>
              <StyledFormGroup controlId="formInput">
                <StyledFormLabel>This bio serves as your introduction to potential poolmates and people you will meet. Make it strong!</StyledFormLabel>
                <StyledTextArea
                  as="textarea"
                  name="bio"
                  value={modalEditData.bio}
                  onChange={handleInputChange}
                  rows={4}
                />
              </StyledFormGroup>
            </>
            
            <>
              <StyledFormGroup controlId="formInput">
                <StyledFormLabel>LinkedIn</StyledFormLabel>
                <StyledFormControl
                  type="text"
                  name="linkedin_url"
                  value={modalEditData.linkedin_url}
                  onChange={handleInputChange}
                />
              </StyledFormGroup>
            </>
        </StyledForm>
      </CustomModal>
    </>
  );
}

export default Edit;
