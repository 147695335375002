import React, { useState, useEffect, useRef } from 'react';
import styled from "styled-components";

import { FlexContainer } from './StyledComponents';
// import { Card } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from './AuthContext';
// import { myAxiosInstance } from './axiosConfig';
// import { StyledBorderCard, StyledButton } from './StyledBootstrap';
// import { useTracking } from 'react-tracking';
// import { ProfilePicture } from './MiscComponents';
// import { useTheme } from 'styled-components';
// import MeetingTimer from './MeetingTimer';



const UserCardContainer = styled.div`
  border-radius: 1.5rem;
  overflow: hidden;
  background: #FFFFFF;
  font-family: Arial, sans-serif;
`;

const Header = styled.div`
  position: relative;
  height: 5.5rem;
  background-size: cover;
  background-position: center;
`;

const BlurOverlay = styled.div`
  position: absolute;
  inset: 0;
  backdrop-filter: blur(1.25rem);
  background: rgba(0, 0, 0, 0.4);
`;

const ProfileImage = styled.img`
  width: 6rem;
  height: 6rem;
  border-radius: 1rem;
  margin-right: 1rem;
`;

const DividerLine = styled.hr`
  width: 95%;
  border: 1px;
  border-top: 0.0625rem solid #E8E9ED; /* 1px */
  margin: auto;
  background: linear-gradient(90deg, #E8E9ED 0%, #E8E9ED 100%);
`;

const DegreeTag = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-align: center; /* Center align text */
  color: #717173;
  background: #F0F1F4; /* Background color */
  padding: 0.25rem 0.625rem;
  border-radius: 2.75rem;
  display: inline-block; /* Inline styling to fit the bubble size */
  box-sizing: border-box; /* Ensures consistent padding */
  margin-right: 1rem;
`;

const MutualImage = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 0.125rem solid #fff;
  margin-left: -0.5rem; /* Overlap images */
  z-index: ${({ index }) => index}; /* Ensure proper stacking */
`;

const MutualCount = styled.span`
  display: flex; /* Flexbox for centering */
  align-items: center; /* Vertical centering */
  justify-content: center; /* Horizontal centering */
  min-width: 2rem; /* Adjust width for a larger bubble */
  height: 2rem; /* Adjust height for a larger bubble */
  padding-right: 0.15rem;
  font-size: 1rem; /* Slightly larger font size */
  font-weight: 400; /* Bold text */
  color: #717173; /* Text color */
  background: #F0F1F4; /* Background color */
  border-radius: 50%; /* Perfectly circular */
  border: 0.125rem solid #fff; /* White border */
  margin-left: -0.5rem; /* Ensure overlap with previous element */
  z-index: 10; /* Ensure it's on top of the previous images */
  box-sizing: border-box; /* Includes padding/border in size calculation */
`;

const RemoveButton = styled.button`
  border: none;
  padding: 0.5rem 1.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 0.375rem;
  background: transparent;
  color: #1D1D26;
  font-family: 'Neu Montreal', Arial, sans-serif;
`;

const AddButton = styled.button`
  border: none;
  padding: 0.5rem 1.5rem;
  font-size: 0.875rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 3.125rem;
  background: #1D1D26;
  color: #ffffff;
  font-family: 'Neu Montreal', Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

const AddSymbol = styled.svg`
  width: 1.5625rem;
  height: 1.5625rem;
  margin-right: 0.0625rem;
  fill: #ffffff;
`;

function UserPreviewCard({ buttonBoolean, bottomReload, setBottomReload, nonUser }) {
  const breakpoints = {
    mobile: '480px',
    tablet: '768px',
    desktop: '1024px',
  };
  
  const user = {
    name: "Saketh Johnson",
    university: "Stanford University",
    year: 2025,
    profileImage:
      "https://images.prospinity.com/profile_pictures/main_profile_picture_andrea-zanon-WYpp.webp",
    degree: "Computer Science",
    mutuals: [
      "https://images.prospinity.com/profile_pictures/main_profile_picture_andrea-zanon-WYpp.webp",
      "https://images.prospinity.com/profile_pictures/main_profile_picture_andrea-zanon-WYpp.webp",
      "https://images.prospinity.com/profile_pictures/main_profile_picture_andrea-zanon-WYpp.webp",
    ],
    mutualCount: 5,
  };

  return (
    <UserCardContainer>

      <Header style={{ backgroundImage: `url(${user.profileImage})` }} >
        <BlurOverlay />
      </Header>

      {/* overall body flexbox */}
      <FlexContainer direction="column" gap="1rem" style={{ padding: "0 2rem 2rem 2rem", marginTop: "-1.5rem"}}> 

        {/* picture / name setion */}
        <FlexContainer align="flex-end" style={{ position: "relative" }}>
          <ProfileImage src={user.profileImage} alt="Profile" />

          <FlexContainer direction="column">
            <h1 style={{ fontSize: "1.5rem", fontWeight: "500", lineHeight: "2rem", margin: "0", color: "#1D1D26" }}>{user.name}</h1>
            <p style={{ fontSize: "1rem", fontWeight: "400", lineHeight: "1.5rem", margin: "0", color: "#717173" }}>
              {user.university} <span style={{ height: "1.2rem", color:"#E8E9ED" }}>|</span> {user.year}
            </p>
          </FlexContainer>
        </FlexContainer>

        <DividerLine />

        <FlexContainer align="center" justify="space-between">
          <DegreeTag>{user.degree}</DegreeTag>

          {/* picture / name setion */}
          <FlexContainer align="center">
            {user.mutuals.slice(0, 3).map((mutual, index) => (
              <MutualImage
                src={mutual}
                alt={`Mutual ${index + 1}`}
                key={index}
                index={index + 1} // Pass index for stacking
              />
            ))}
            {user.mutualCount > 3 && user.mutualCount < 13 && (
              <MutualCount>+{user.mutualCount - 3}</MutualCount>
            )}
            <span style={{ fontSize: "1rem", fontWeight: "400", color: "#717173", marginLeft: "0.5rem" }}>{user.mutualCount} mutuals</span>
          </FlexContainer>
        </FlexContainer>

        <DividerLine />
 
        <FlexContainer align="center" justify="flex-end">
          {buttonBoolean && <RemoveButton>REMOVE</RemoveButton>}

          <AddButton>
            <AddSymbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M12 5v14m-7-7h14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </AddSymbol>
            ADD
          </AddButton>
        </FlexContainer>

      </FlexContainer>
    </UserCardContainer>
  );
}

export default UserPreviewCard;




// function UserPreviewCard({ preview_object, buttonBoolean, bottomReload, setBottomReload, nonUser }) {
//     const themeMode = useTheme();
//     const { userSlug, reload, setReload, setActiveChannel, setChatVisible } = useAuth();
//     const [isIndividualMessageLoading, setIsIndividualMessageLoading] = useState(false);

//     const slug = preview_object.slug;
//     const fullName = nonUser ? preview_object.full_name : `${preview_object.first_name} ${preview_object.last_name}`;
    
//     const majors = nonUser ? "Student" : Array.isArray(preview_object.major_or_grad_program)
//         ? preview_object.major_or_grad_program.join(', ')
//         : preview_object.major_or_grad_program;
//     const university = preview_object.university;
//     const company = nonUser ? "Student" : preview_object.company;

//     const [pictureSize, setPictureSize] = useState(0);
//     const cardRef = useRef(null);
//     const navigate = useNavigate();

//     const titleSize = pictureSize * 0.15;
//     const majorSize = pictureSize * 0.1;

//     const handleUserClick = () => {
//         !nonUser && navigate('/dashboard/user/' + preview_object.slug);
//     }

//     const handleMessageButtonClick = async () => {
//         try {
//             setIsIndividualMessageLoading(true);
//             const response = await myAxiosInstance.post('/api/create-channel/', { recipient_slug: slug });
//             setActiveChannel(response.data.channel_id);
//             setChatVisible(true);

//         }
//         catch (error) {
// }
//         finally {
//             setIsIndividualMessageLoading(false);
//         }
//     };

//     const handleUserPreviewButtonClick = () => {

//         if (preview_object.meeting_status !== "not_meeting")
//         {
//             navigate('/dashboard/meeting/' + preview_object.meeting_slug);            
//         }
//         else if (preview_object.portfolio_status === "in_portfolio"){
//             // Chat
//             handleMessageButtonClick();
//         }
//         else{
//             navigate('/dashboard/user/' + preview_object.slug, { state: { addToPortfolio: true } });
//         }
//     }

//     const buttonLabels = ["Add", "Sent", "Chat"];

//     useEffect(() => {
//         const updateCardSize = () => {
//             if (cardRef.current) {
//                 const cardWidth = cardRef.current.offsetWidth;
//                 const size = Math.min(cardWidth, cardWidth * 1.6) * 0.7; // Adjusted size when not hovered
//                 setPictureSize(size);
//             }
//         };

//         updateCardSize();
//         window.addEventListener('resize', updateCardSize);

//         return () => window.removeEventListener('resize', updateCardSize);
//     }, []);

//     return (
//         <>
//             <StyledBorderCard
//                 ref={cardRef}
                
//                 style={{
//                     width: '8rem',
//                     height: buttonBoolean ? '14.3rem' : '12rem', // Fixed size when not hovered
//                     transition: 'transform 0.3s ease',
//                     transform: 'scale(1)',
//                     boxShadow: '0 0 10px rgba(0,0,0,0.1)',
//                     marginBottom: '0rem', // Adjusted margin bottom to reduce space between cards
                    
//                 }}
//                 onMouseEnter={() => {
//                     cardRef.current.style.transform = 'scale(1.04)';
//                     cardRef.current.style.boxShadow = '0 0 20px rgba(0,0,0,0.2)';
//                 }}
//                 onMouseLeave={() => {
//                     cardRef.current.style.transform = 'scale(1)';
//                     cardRef.current.style.boxShadow = '0 0 10px rgba(0,0,0,0.1)';
//                 }}
//             >
//                 <StyledBorderCard.Body style={{ textAlign: 'center'}}>
//                     <div onClick={handleUserClick}>
//                     <div className='d-flex justify-content-center'>
//                         <ProfilePicture name={fullName} size={pictureSize} graduation_date={preview_object.graduation_date} picture_link={preview_object.profile_picture} />
//                     </div>
//                     <StyledBorderCard.Title
//                         style={{
//                             cursor: 'pointer',
//                             textAlign: 'center',
//                             whiteSpace: 'nowrap',
//                             overflow: 'hidden',
//                             textOverflow: 'ellipsis',
//                             fontSize: `${titleSize}px`,
//                             marginTop: '0.3em'
//                         }}>
//                         {fullName}
//                     </StyledBorderCard.Title>
//                     <StyledBorderCard.Text
//                         style={{
//                             textAlign: 'center',
//                             overflow: 'hidden',
//                             textOverflow: 'ellipsis',
//                             maxWidth: '100%',
//                             fontSize: `${majorSize}px`,
//                             display: '-webkit-box',
//                             WebkitBoxOrient: 'vertical',
//                             WebkitLineClamp: 1,
//                             lineHeight: '1.2em',
//                         }}>
//                         {company === "" ? majors || 'Not Specified' : company || 'Not Specified'}
//                     </StyledBorderCard.Text>
//                     <StyledBorderCard.Text
//                         style={{
//                             textAlign: 'center',
//                             overflow: 'hidden',
//                             textOverflow: 'ellipsis',
//                             maxWidth: '100%',
//                             fontSize: `${majorSize}px`,
//                             display: '-webkit-box',
//                             WebkitBoxOrient: 'vertical',
//                             WebkitLineClamp: 1,
//                             lineHeight: '1.2em',
//                         }}>
//                         {university || preview_object.email}
//                     </StyledBorderCard.Text>
                    
//                     </div>
//                     {buttonBoolean ? 
//                     <StyledButton className='mt-2' onClick={handleUserPreviewButtonClick} style={{ lineHeight: '1', fontSize: '0.75rem', minWidth: '95%', backgroundColor: preview_object.meeting_status === "you_requested" || preview_object.meeting_status === "confirmed" || preview_object.meeting_status === "you_rescheduled" ? themeMode.background : themeMode.primary, color: preview_object.meeting_status === "you_requested" || preview_object.meeting_status === "confirmed" || preview_object.meeting_status === "you_rescheduled" ? themeMode.onBackground : themeMode.onPrimary}}>
//                         {
//                             preview_object.portfolio_status === "you_requested" ? buttonLabels[1] :
//                             preview_object.portfolio_status === "you_received" ? buttonLabels[2] :
//                             preview_object.portfolio_status === "confirmed" ? <MeetingTimer meetingTime={preview_object.meeting_date} onTime={() => { setBottomReload(bottomReload + 1) }} /> :
//                             preview_object.portfolio_status === "you_rescheduled" ? buttonLabels[4] :
//                             preview_object.portfolio_status === "other_user_rescheduled" ? buttonLabels[5] :
//                             preview_object.portfolio_status === "in_progress" ? buttonLabels[6] :
//                             preview_object.portfolio_status === "in_portfolio" ? buttonLabels[7] : 
//                             preview_object.portfolio_status === "not_meeting" ? buttonLabels[0] : <></>
//                         }
//                     </StyledButton> : <></>}
//                 </StyledBorderCard.Body>
//             </StyledBorderCard>
//         </>
//     );
// }

// export default UserPreviewCard;