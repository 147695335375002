import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { convertUTCtoLocalDateTime } from './CriticalFunctions';
import { Accordion, Card, Form } from 'react-bootstrap';
import MultiSelectUsers from './MultiSelectUsers';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { myAxiosInstance } from './axiosConfig';
import MultiSelectEmails from './MultiSelectEmails';

import { StyledAccordion, StyledForm, StyledFormGroup, StyledFormLabel, StyledFormControl, StyledFormCheck, StyledParagraph } from './StyledBootstrap';


//make manage invitations separate, or update otherusers
//what happes if close after some updates
//anyway to make all of it one put request


function ManagePool({ editPoolData, setEditPoolData, slug, setModalMessage, setDelPool, delPool, removeInvitees, setRemoveInvitees, addInvitees, setAddInvitees, manageReload, inviteEmails, setInviteEmails }) {

    const [isBottomLoading, setIsBottomLoading] = useState(true);
    const [otherUsers, setOtherUsers] = useState([]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditPoolData({ ...editPoolData, [name]: value });
    };

    useEffect(() => {
        const getPoolData = async () => {
            try {
                setDelPool(false);
                setRemoveInvitees([]);
                setAddInvitees([]);
                setOtherUsers([]);
                setIsBottomLoading(true);
                const response = await myAxiosInstance.get('/api/pools/' + slug + '/');
                const data = response.data.public_details; // Storing the response data in a local variable
                setEditPoolData({ ...data, name: response.data.private_details.name, invite_emails: [...response.data.other_users.map(user => user.email)], percent_signitures_to_activate: response.data.extra_private_details.percent_signitures_to_activate });
                setOtherUsers(response.data.other_users);
                setInviteEmails(response.data.extra_private_details.invited_users_emails);
            } catch (error) {
                setModalMessage('Error fetching pool details');
            } finally {
                setIsBottomLoading(false);
            }
        };

        getPoolData();
    }, [slug, manageReload]);



    return (
        <>
            {
                isBottomLoading ?
                    <div>
                        <StyledParagraph>Loading...</StyledParagraph>
                    </div>

                    :

                    <StyledAccordion defaultActiveKey="0">
                        {/* Edit Pool Formation Settings */}

                        <StyledAccordion.Item eventKey="0">
                            <StyledAccordion.Header>Edit Pool Formation Settings <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledAccordion.Header>
                            <StyledAccordion.Body>
                                <StyledForm>
                                    {/* Fields for Pool Formation Settings */}
                                    <StyledFormGroup>
                                        <StyledFormLabel>Name</StyledFormLabel>
                                        <StyledFormControl
                                            type="text"
                                            name="name"
                                            value={editPoolData.name || ''}
                                            onChange={handleInputChange}
                                        />
                                    </StyledFormGroup>
                                    <StyledFormGroup>
                                        <StyledFormLabel>Invitation Deadline (in your timezone)</StyledFormLabel>
                                        <StyledFormControl
                                            type="datetime-local"
                                            name="invitation_deadline"
                                            value={convertUTCtoLocalDateTime(editPoolData.invitation_deadline) || ''}
                                            onChange={e => setEditPoolData({ ...editPoolData, invitation_deadline: new Date(e.target.value).toISOString() })}
                                        />
                                    </StyledFormGroup>
                                    <StyledFormGroup>
                                        <StyledFormLabel>Sign Deadline (in your timezone)</StyledFormLabel>
                                        <StyledFormControl
                                            type="datetime-local"
                                            name="sign_deadline"
                                            value={convertUTCtoLocalDateTime(editPoolData.sign_deadline) || ''}
                                            onChange={e => setEditPoolData({ ...editPoolData, sign_deadline: new Date(e.target.value).toISOString() })}
                                        />
                                    </StyledFormGroup>
                                    <StyledFormGroup className="mb-3">
                                        <StyledFormLabel>Percent Signatures to Activate (in percent):</StyledFormLabel>
                                            <StyledFormControl
                                                type="number"
                                                name="percent_signitures_to_activate"
                                                value={editPoolData.percent_signitures_to_activate || 0}
                                                onChange={handleInputChange}
                                            />
                                    </StyledFormGroup>
                                    
                                </StyledForm>


                            </StyledAccordion.Body>
                        </StyledAccordion.Item>
                        <StyledAccordion.Item eventKey="1">
                            <StyledAccordion.Header>Edit Pool Terms  <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledAccordion.Header>
                            <StyledAccordion.Body>

                                <StyledForm>
                                    {/* Fields for Pool Terms */}
                                    <StyledFormGroup>
                                        <StyledFormLabel>Threshold ($)</StyledFormLabel>
                                        <StyledFormControl
                                            type="text"
                                            name="threshold"
                                            value={editPoolData.threshold || 0}
                                            onChange={handleInputChange}
                                        />
                                    </StyledFormGroup>
                                    <StyledFormGroup>
                                        <StyledFormLabel>Contribution Percent (%)</StyledFormLabel>
                                        <StyledFormControl
                                            type="number"
                                            name="contribution_percent"
                                            value={editPoolData.contribution_percent || 0}
                                            onChange={handleInputChange}
                                        />
                                    </StyledFormGroup>
                                    <StyledFormGroup>
                                        <StyledFormLabel>Time Horizon (years)</StyledFormLabel>
                                        <StyledFormControl
                                            type="number"
                                            name="time_horizon"
                                            value={editPoolData.time_horizon || 0}
                                            onChange={handleInputChange}
                                        />
                                    </StyledFormGroup>
                                    <StyledFormGroup>
                                        <StyledFormLabel>Minimum Income ($)</StyledFormLabel>
                                        <StyledFormControl
                                            type="text"
                                            name="minimum_income"
                                            value={editPoolData.minimum_income || 0}
                                            onChange={handleInputChange}
                                        />
                                    </StyledFormGroup>

                                </StyledForm>

                            </StyledAccordion.Body>
                        </StyledAccordion.Item>

                        <StyledAccordion.Item eventKey="2">
                            <StyledAccordion.Header>Manage Invitations  <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledAccordion.Header>
                            <StyledAccordion.Body>
                                <MultiSelectUsers
                                    otherUsers={otherUsers}
                                    value={removeInvitees}
                                    onChange={(selected) => {
                                        setRemoveInvitees(selected);
                                    }}
                                    label="Remove Invitees"
                                    endpoint={false}
                                />
                                <MultiSelectUsers
                                    otherUsers={otherUsers}
                                    value={addInvitees}
                                    onChange={(selected) => {
                                        setAddInvitees(selected);
                                    }}
                                    label="Add Invitees"
                                    endpoint={true}
                                />
                                <MultiSelectEmails
                                    value={inviteEmails}
                                    label="Emails"
                                    onChange={(selected) => { setInviteEmails(selected) }}
                                />
                            </StyledAccordion.Body>
                        </StyledAccordion.Item>
                        <StyledAccordion.Item eventKey="3">
                            <StyledAccordion.Header>Delete Pool  <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledAccordion.Header>
                            <StyledAccordion.Body>
                                <StyledFormGroup>
                                    <div>
                                        <StyledFormCheck
                                            type="checkbox"
                                            name="reset_if_pool_fails"
                                            id="reset_if_pool_fails"
                                            label="Before saving changes, make sure you are absolutely certain."
                                            checked={delPool}
                                            onChange={e => setDelPool(!delPool)}
                                        />
                                    </div>
                                </StyledFormGroup>
                            </StyledAccordion.Body>
                        </StyledAccordion.Item>
                    </StyledAccordion>
            }
        </>


    );
}

export default ManagePool;