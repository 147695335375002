import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import WhiteLogo from "../Images/logo_white.png";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #000; /* Pure black background */
  color: white;
  overflow-y: auto; /* Enable scrolling for smaller screens */
  font-family: "Playfair Display", serif; /* Premium font for elegance */
  padding: 2rem;
  scroll-behavior: smooth; /* Smooth scrolling */
`;

const Logo = styled(motion.img)`
  width: ${({ isMdOrLarger }) => (isMdOrLarger ? "15rem" : "10rem")};
  margin-bottom: 2rem;
  align-self: center; /* Center the logo */
`;

const ApplicationText = styled(motion.div)`
  font-size: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.6rem" : "1.2rem")};
  line-height: 1.8;
  text-align: center;
  color: rgba(255, 255, 255, 0.9); /* Slightly softer white */
  letter-spacing: 0.02rem;
  padding: 0 2rem;
  margin-bottom: 2rem;

  ol {
    text-align: left;
    margin: 1.5rem auto;
    padding-left: 1.5rem;
    max-width: ${({ isMdOrLarger }) => (isMdOrLarger ? "70%" : "90%")};
  }

  li {
    margin-bottom: 1rem;
  }

  a {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: underline;
    cursor: pointer;
    transition: color 0.3s ease;
  }

  a:hover {
    color: white;
  }
`;

const Divider = styled(motion.div)`
  width: 60%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 2rem auto;
`;

const NavigationButtons = styled(motion.div)`
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  font-size: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.4rem" : "1.2rem")};
  font-family: "Playfair Display", serif; /* Premium font for elegance */
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;

  span {
    transition: color 0.2s ease;
  }

  span:hover {
    color: white;
  }
`;


export default function ExplainPage({ navigateToIntro, navigateToApplication }) {
  const [fadeOut, setFadeOut] = useState(false); // For fading out the page
  const isMdOrLarger = useMediaQuery({ minWidth: 768 });

  // Refs for smooth scrolling
  const sectionRefs = useRef([]);

  const handleNavigateToIntroPage = () => {
    setFadeOut(true);
    setTimeout(() => {
      navigateToIntro();
    }, 1500);
  };

  const handleNavigateToApplication = () => {
    setFadeOut(true);
    setTimeout(() => {
      navigateToApplication();
    }, 1500);
  };

  // Helper function to check if an element is in view
  const isInViewport = (el) => {
    if (!el) return false;
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const scrollToSection = (index) => {
    const el = sectionRefs.current[index];
    if (el && !isInViewport(el)) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <PageContainer
      as={motion.div}
      initial={{ opacity: 1, y: 0 }}
      animate={fadeOut ? { opacity: 0, y: -50 } : { opacity: 1, y: 0 }}
      transition={{ duration: 2 }}
    >
      {/* Logo Section */}
      <Logo
        src={WhiteLogo}
        alt="Prospinity Logo"
        isMdOrLarger={isMdOrLarger}
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1.5, delay: 0 }} // Slightly slower
      />

      {/* Content Section */}
      <ApplicationText isMdOrLarger={isMdOrLarger}>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.5, delay: 1.2 }} // Slightly slower
          onAnimationComplete={() => scrollToSection(0)}
          ref={(el) => (sectionRefs.current[0] = el)}
        >
          We're a fintech startup, backed by the investors behind Robinhood,
          Venmo, Solana, and Sam Altman.
        </motion.p>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.5, delay: 2.6 }} // Increased delay
          onAnimationComplete={() => scrollToSection(1)}
          ref={(el) => (sectionRefs.current[1] = el)}
        >
          We create success pools, groups of elite students who share a small
          percentage of their <em>future</em> success.
        </motion.p>
        <Divider
          initial={{ scaleX: 0 }}
          animate={{ scaleX: 1 }}
          transition={{ duration: 1.8, delay: 4.2 }} // Slower divider animation
          onAnimationComplete={() => scrollToSection(2)}
          ref={(el) => (sectionRefs.current[2] = el)}
        />
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.8, delay: 5.2 }} // Slower fade-in for "How it works"
          onAnimationComplete={() => scrollToSection(3)}
          ref={(el) => (sectionRefs.current[3] = el)}
        >
          <strong>How it works…</strong>
        </motion.p>
        <motion.ol>
          {[...Array(4).keys()].map((i) => (
            <motion.li
              key={i}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: 1.5, // Slightly slower list item animation
                delay: 6.5 + i * 0.9, // Slower staggered delay
              }}
              onAnimationComplete={() => scrollToSection(4 + i)}
              ref={(el) => (sectionRefs.current[4 + i] = el)}
            >
              {[
                "When we launch at a new campus, students can apply for our pools.",
                "Selected students will advance to an interview stage.",
                "Some will be invited to join selected pools that we feel are a good fit.",
                "Those students decide whether to accept the invite.",
              ][i]}
            </motion.li>
          ))}
        </motion.ol>
        <Divider
          initial={{ scaleX: 0 }}
          animate={{ scaleX: 1 }}
          transition={{ duration: 1.8, delay: 10.2 }}
          onAnimationComplete={() => scrollToSection(8)}
          ref={(el) => (sectionRefs.current[8] = el)}
        />
        {[...Array(3).keys()].map((i) => (
          <motion.p
            key={i}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 1.5,
              delay: 11 + i * 1, // Slower stagger for "Check out" section
            }}
            onAnimationComplete={() => scrollToSection(9 + i)}
            ref={(el) => (sectionRefs.current[9 + i] = el)}
          >
            {[
              <>
                Check out the Berkeley launch page{" "}
                <a href="/berkeley-launch">here</a>.
              </>,
              <>
                Check out how pools work{" "}
                <a href="/how-pools-work">here</a>.
              </>,
              <>
                Watch the official launch video{" "}
                <a href="/launch-video">here</a>.
              </>,
            ][i]}
          </motion.p>
        ))}
      </ApplicationText>

      {/* Navigation Buttons */}
      <NavigationButtons
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5, delay: 14.5 }}
        isMdOrLarger={isMdOrLarger}
      >
        <span onClick={handleNavigateToIntroPage}>← back</span>
        <span onClick={handleNavigateToApplication}>start now (5min) →</span>
      </NavigationButtons>
    </PageContainer>
  );
}