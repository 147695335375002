import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle  } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { stringToColor } from './CriticalFunctions';
import { myAxiosInstance } from './axiosConfig';
import { formatGraduationDate } from './CriticalFunctions';
import { useAuth } from './AuthContext';
import { useTheme } from 'styled-components';
import { StyledDropdown, StyledFormGroup, StyledFormControl, StyledFormLabel } from './StyledBootstrap';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { name } from '@stream-io/video-react-sdk';

const profilePictureStyle = {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '10px'
};

const customDropdownItemStyle = {
    width: '100%',
    backgroundColor: 'white',
    padding: '5px',
    cursor: 'pointer'
};

const customDropdownItemHoverStyle = {
    backgroundColor: '#e0e0e0'
};



const AddUserToPool = forwardRef(({ otherUsers, value, onChange, label, endpoint, deleteUser }, ref) => 
{
    const [emailInput, setEmailInput] = useState(value.email);
    //const [emailInput, setEmailInput] = useState('');
    const [nameInput, setNameInput] = useState(value.first_name !== '' ? value.first_name + " " + value.last_name : '');
    //const [nameInput, setNameInput] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [selectedUser, setSelectedUser] = useState(value || null);
    //const [selectedUser, setSelectedUser] = useState(null);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showDropdown, setShowDropdown] = useState(false);
    const wrapperRef = useRef(null);


    useImperativeHandle(ref, () => ({
        getData: () => emailInput
    }));




    const themeMode = useTheme();
    const { userSlug } = useAuth();

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowDropdown(false);
            }        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const fetchOptions = async (query) => {
        try {
            if (endpoint) {
                const response = await myAxiosInstance.get(`/api/search-users/?q=${query}&limit_users=true`);
                return response.data.users;
            }
            return otherUsers.filter(item =>
                (item.first_name + " " + item.last_name).toLowerCase().startsWith(query.toLowerCase())
            );
        } catch (error) {
            return [];
        }
    };

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if (emailInput || nameInput) {
                fetchOptions(emailInput || nameInput).then(data => {
                    setSuggestions(data.filter(item => item.slug !== selectedUser?.slug));
                });
            } else {
                setSuggestions([]);
            }
        }, 100);

        return () => clearTimeout(debounceTimer);
    }, [emailInput, nameInput]);

    const handleEmailChange = (event) => {
        const email = event.target.value;
        setEmailInput(email);
        setShowDropdown(true);
        setActiveSuggestionIndex(0);
        setSelectedUser(null);
        let tempItem = {email: email}
        onChange(tempItem);
    };

    const handleNameChange = (event) => {
        const name = event.target.value;
        setNameInput(name);
        setShowDropdown(true);
        setActiveSuggestionIndex(0);
        setSelectedUser(null);
        let tempItem = {first_name: name, last_name: ''}
        onChange(tempItem);
    };

    const handleSelect = (item) => {
        setSelectedUser(item);
        setEmailInput(item.email);
        setNameInput(`${item.first_name} ${item.last_name}`);
        setShowDropdown(false);
        onChange(item);
    };

    const handleRemoveSelection = () => {
        setSelectedUser(null);
        setEmailInput('');
        setNameInput('');
        onChange({
            company: '',
            email: '',
            first_name: '',
            graduation_date: '',
            is_verified: '',
            last_name: '',
            major_or_grad_program: '',
            pooled: '',
            portfolio_count: '',
            profile_picture: '',
            slug: '',
            university: ''});
    };

    return (
        <StyledFormGroup className='mb-3'>
            <StyledFormLabel>
            <FontAwesomeIcon
                    icon={faTimes}
                    onClick={deleteUser}
                    style={{
                        cursor: 'pointer',
                        marginRight: '5px',
                        color: themeMode.primary,
                        fontSize: '1rem',
                    }}
                    title="Remove this user"
                />
                Member {label}
                
            </StyledFormLabel>

            

            {/* Second FormControl for Full Name */}
            <InputGroup  className="mb-2">
                <StyledFormControl
                    type="text"
                    value={nameInput}
                    placeholder="Enter name (first and last)"
                    onChange={handleNameChange}
                    readOnly={false}  // Allow manual entry if no selection
                />
            </InputGroup>

            {/* First FormControl for Email */}
            <InputGroup>
                <StyledFormControl
                    type="text"
                    value={emailInput}
                    placeholder="Enter university email"
                    onChange={handleEmailChange}
                    readOnly={false}  // Allow manual entry if no selection
                />
                {selectedUser && (
                    <InputGroup.Text onClick={handleRemoveSelection} style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon icon={faTimes} />
                    </InputGroup.Text>
                )}
            </InputGroup>
            <div ref={wrapperRef}>
                <StyledDropdown show={showDropdown && suggestions.length > 0}>
                    <StyledDropdown.Menu align={'start'} style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: '100%',
                        width: '100%',
                        maxHeight: '400px',
                        overflowY: 'auto',
                    }}>
                        {suggestions.map((item, index) => (
                            <StyledDropdown.Item
                                key={item.slug}
                                active={index === activeSuggestionIndex}
                                onClick={() => handleSelect(item)}
                                style={{
                                    ...customDropdownItemStyle,
                                    ...(index === activeSuggestionIndex ? customDropdownItemHoverStyle : {})
                                }}
                                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = customDropdownItemHoverStyle.backgroundColor}
                                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = customDropdownItemStyle.backgroundColor}
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img
                                        src={item.profile_picture || 'https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW.webp'}
                                        alt="Profile"
                                        style={profilePictureStyle}
                                    />
                                    <span style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: 'calc(100% - 50px)'  // Adjust based on padding/margins
                                    }}>
                                        {`${item.first_name} ${item.last_name} '${formatGraduationDate(item.graduation_date)}, ${item.university}, ${item.major_or_grad_program.map(program => program).join(", ")}${item.company ? `, ${item.company}` : ""}`}
                                    </span>
                                </div>
                            </StyledDropdown.Item>
                        ))}
                    </StyledDropdown.Menu>
                </StyledDropdown>
            </div>
        </StyledFormGroup>
    );
});

export default AddUserToPool;