import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000; /* Pure black background */
  color: white;
  overflow: hidden;
  font-family: "Playfair Display", serif; /* Premium font for elegance */
`;

const QuoteContainer = styled(motion.div)`
  text-align: center;
  max-width: ${({ isMdOrLarger }) => (isMdOrLarger ? "70%" : "90%")};
  font-family: "Cormorant Garamond", serif; /* Premium, poetic font */
  margin: 0 auto;
`;

const QuoteLine = styled.div`
  line-height: 1.5;
  margin: 0;
  font-style: italic;
  text-align: center;
`;

const QuoteBy = styled.div`
  text-align: right;
  margin-top: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.5rem" : "0.2rem")};
  color: rgba(255, 255, 255, 0.8);
  font-style: italic;
  padding-right: ${({ isMdOrLarger }) => (isMdOrLarger ? "2rem" : "1rem")};
`;

const letterVariants = {
  hidden: { opacity: 0 },
  visible: ([i, baseDelay]) => ({
    opacity: 1,
    transition: {
      delay: 0.04 * i + baseDelay, // Faster overlapping animation
      duration: 2, // Faster fade-in duration
    },
  }),
};

export default function QuotePage({ onComplete }) {
  const isMdOrLarger = useMediaQuery({ minWidth: 768 });
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const fadeOutTimer = setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => {
        onComplete();
      }, 1500); // Delay matches fade-out duration
    }, 14000); // Wait for animations to complete

    return () => clearTimeout(fadeOutTimer);
  }, [onComplete]);

  const renderLine = (text, baseDelay, quoteBy) =>
    text.split("").map((char, index) => (
      <motion.span
        key={index}
        custom={[index, baseDelay]}
        initial="hidden"
        animate="visible"
        variants={letterVariants}
        style={{ display: "inline-block", fontSize: isMdOrLarger ? (quoteBy ? "1.6rem" : "2.4rem") : (quoteBy ? "0.8rem" : "1rem") }}
      >
        {char === " " ? "\u00A0" : char}
      </motion.span>
    ));

  return (
    <PageContainer
      as={motion.div}
      initial={{ opacity: 1 }}
      animate={fadeOut ? { opacity: 0 } : { opacity: 1 }}
      transition={{ duration: 1.5 }}
    >
      <QuoteContainer>
        {/* Line 1 */}
        <QuoteLine>
          {renderLine("“Two roads diverged in a wood, and I—", 0.3, false)}
        </QuoteLine>

        {/* Line 2 */}
        <QuoteLine>
          {renderLine("I took the one less traveled by,", 3, false)}
        </QuoteLine>

        {/* Line 3 */}
        <QuoteLine>
          {renderLine("But everyone else was in a success pool taking both.”", 6.8, false)}
        </QuoteLine>

        {/* Attribution */}
          <QuoteBy>
            {renderLine("— Adapted from Robert Frost", 10, true)}
          </QuoteBy>
      </QuoteContainer>
    </PageContainer>
  );
}


function Transition({ onComplete }) {
  const isMdOrLarger = useMediaQuery({ minWidth: 768 });
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const fadeOutTimer = setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => {
        onComplete();
      }, 1500); // Delay matches fade-out duration
    }, 7000); // Wait for animations to complete

    return () => clearTimeout(fadeOutTimer);
  }, [onComplete]);

  const renderLine = (text, baseDelay, quoteBy) =>
    text.split("").map((char, index) => (
      <motion.span
        key={index}
        custom={[index, baseDelay]}
        initial="hidden"
        animate="visible"
        variants={letterVariants}
        style={{ display: "inline-block", fontSize: isMdOrLarger ? (quoteBy ? "1.6rem" : "2.4rem") : (quoteBy ? "0.8rem" : "1rem") }}
      >
        {char === " " ? "\u00A0" : char}
      </motion.span>
    ));

    return (
      <PageContainer
        as={motion.div}
        initial={{ opacity: 1 }}
        animate={fadeOut ? { opacity: 0 } : { opacity: 1 }}
        transition={{ duration: 1.5 }}
      >
        <QuoteContainer style={{ fontFamily: "Playfair Display" }}>
          {/* Line 1 */}
          <QuoteLine>
            {renderLine("Starting the application.", 0.3, false)}
          </QuoteLine>
  
          {/* Line 2 */}
          <QuoteLine>
            {renderLine("Don't spend more than 5 minutes on this.", 3, false)}
          </QuoteLine>
  
        </QuoteContainer>
      </PageContainer>
    );
}

export {Transition};


