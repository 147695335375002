import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button, Row, Col, Container, InputGroup, Dropdown } from 'react-bootstrap';
import { useTheme } from 'styled-components';
import { StyledButton, StyledCard, StyledFormControl, StyledFormGroup, StyledFormLabel, StyledForm, StyledParagraph } from './StyledBootstrap';
import logo from './Images/Logo1.png';
import logo_gradient from './Images/logo_gradient.png';
import logo_white from './Images/logo_white.png';
import logo_black from './Images/logo_black.png';
import logo_navy from './Images/logo_navy.png';
import axios from 'axios';
import EmailVerificationSent from './EmailVerificationSent';

function PasswordResetEmail() {
    const themeMode = useTheme();
    const { verificationKey } = useParams();
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');

    const [errors, setErrors] = useState({ email: false, non_field_errors: false });


    const firstInputRef = useRef(null);
    useEffect(() => {
        // Focus the appropriate input field based on the step
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, []);


    async function sendPasswordVerificationEmail(setIsLoading, email, setMessage) {
        try {
            setIsLoading(true);
            const response = await axios.post('/prospinity-auth/password/reset/', { "email": email });
            setMessage("Password reset link sent to your email.");
        } catch (error) {
            if (error.response && error.response.data) {
                // Construct the error list JSX and pass it to setMessage
                let tempErrors = { email: false, non_field_errors: false }
                for (const [key, value] of Object.entries(error.response.data)) {
                    tempErrors = { ...tempErrors, [key]: true };
                }
                setErrors(tempErrors);

                if (tempErrors.non_field_errors) {
                    setMessage(error.response.data.non_field_errors);
                }

            }
            else {
                setMessage("Cannot send reset link with password.");
            }
            /*
            setMessage(Object.values(error.response.data)[0][0]);*/
        }
        finally {
            setIsLoading(false);
        }
    }


    return (
        message === "Password reset link sent to your email." ?

            <EmailVerificationSent><StyledParagraph className='mt-3'>{message}</StyledParagraph></ EmailVerificationSent>
            :
            <div>
                <Container fluid style={{ height: '100vh', backgroundColor: themeMode.background }} className='d-flex flex-column justify-content-center'>
                    <Row className='justify-content-center'>
                        <Col className='col-lg-4 col-md-6'>

                            <StyledCard>
                                <StyledCard.Body>
                                    <Row className='justify-content-center mb-4'>
                                        <Col md={10} className="text-center">
                                            <img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="40rem" />
                                        </Col>
                                    </Row>
                                    <StyledFormGroup controlId="formBasicEmail">
                                        <StyledFormControl
                                            type="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            ref={firstInputRef}
                                            hasError={errors.email}
                                            onClick={() => setErrors({ ...errors, email: false })}
                                        />
                                        {errors.email ? <StyledParagraph className='mt-1' style={{ fontSize: '0.7rem', color: themeMode.error }}>Please enter a valid email.</StyledParagraph> : <></>}

                                    </StyledFormGroup>
                                    <StyledButton variant="primary" onClick={() => sendPasswordVerificationEmail(setIsLoading, email, setMessage)} className="w-100">
                                        Send Reset Link
                                    </StyledButton>
                                    <div className='text-center mt-3'>
                                        {isLoading && <StyledParagraph>Sending Email...</StyledParagraph>}
                                        {message && <StyledParagraph>{message}</StyledParagraph>}
                                        <StyledParagraph>Go back to <Link to="/signin">Log In.</Link></StyledParagraph>

                                    </div>
                                </StyledCard.Body>
                            </StyledCard>
                        </Col>
                    </Row>
                </Container>

            </div>);
}

export default PasswordResetEmail;


