import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { Nav, FormControl, Row, Col, Container, InputGroup, Dropdown, Navbar, Button } from 'react-bootstrap';
import logo from './Images/logo3.png';
import logo1 from './Images/Logo1.png';
import logo_gradient from './Images/logo_gradient.png';
import logo_white from './Images/logo_white.png';
import logo_black from './Images/logo_black.png';
import logo_navy from './Images/logo_navy.png';
import { useAuth } from './AuthContext';
import './Dashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // If using Font Awesome for icons
import SearchUsers from './SearchUsers';
import { faHome, faQuestion, faUser, faSwimmer, faFileInvoiceDollar, faSignOut, faChartLine, faCreditCard, faCheck, faSearch, faBell, faPlus, faGear, faArrowLeft } from '@fortawesome/free-solid-svg-icons'; // Example icons
import { useMediaQuery } from 'react-responsive';
import { ProfilePicture } from './MiscComponents';
import { timeSince } from './CriticalFunctions';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useErrorBoundary } from "react-error-boundary";
import { StyledNavbar, StyledDropdown, StyledButton, StyledH5, StyledNavLink } from './StyledBootstrap';
import { useTheme } from 'styled-components';
import { myAxiosInstance } from './axiosConfig';
import Notifications from './Notifications';
import NotificationsSmall from './NotficiationsSmall';

function NavbarSmallScreen({ setShowReferModal, setWhyModalShow }) {
    const [expanded, setExpanded] = useState(false);
    const themeMode = useTheme();
    const { userSlug, handleLogout, userFirstName, userLastName, userGraduationDate } = useAuth();
    const navigate = useNavigate();
    const navbarRef = useRef(null);

    const [search, setSearch] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navbarRef.current && !navbarRef.current.contains(event.target)) {
                setExpanded(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [navbarRef]);

    const logOutNavigate = () => {
        handleLogout();
        navigate('/signing-out');
    };

    const getBaseFontSize = () => {
        const fontSize = window.getComputedStyle(document.body).fontSize;
        return parseFloat(fontSize);
    };

    const toggleNavbar = () => {
        setExpanded(!expanded);
    };

    // Effect to handle clicks outside the navbar
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navbarRef.current && !navbarRef.current.contains(event.target)) {
                setExpanded(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [navbarRef]);

    // Effect to focus the search input when search is true
    useEffect(() => {
        if (search) {
            
                const searchInput = document.querySelector('.search-users-small');
                if (searchInput) {
                    searchInput.focus();
                }

            
        }
    }, [search]); // Ru

    return (
        <StyledNavbar ref={navbarRef} expanded={expanded} expand="lg" sticky="top">
            {search ? 
            <div className='d-flex w-100 align-items-center'>
                <div style={{fontSize: '1.5rem'}} onClick={() => setSearch(false)}>
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                </div>
                <SearchUsers />
            </div>
             : <>
            <div className='d-flex w-100 justify-content-between'>
                <div>
                    <Navbar.Brand href="/dashboard/home" className="d-flex align-items-center ml-2">
                        <img src={themeMode.theme === 'dark' ? logo_white : logo_navy} height="30rem" className="d-inline-block align-top" alt="Logo" />
                    </Navbar.Brand>
                </div>
                <div className='d-flex align-items-center'>
                    <div style={{fontSize: '1.5rem'}} onClick={() => setSearch(true)} className='search-joyrride'>
                    <FontAwesomeIcon icon={faSearch} className="mr-2" />
                    </div>
                    <NotificationsSmall className='d-flex justify-content-end' />
                    <Navbar.Toggle onClick={toggleNavbar} aria-controls="responsive-navbar-nav" style={{ border: '0px' }} className='profile-dropdown-joyrride'/>
                </div>
            </div>
            <Navbar.Collapse id="responsive-navbar-nav">
                {/*<Nav.Link as={Link} to="create-pool" style={{ color: themeMode.onSurface }} className='create-joyrride'>Create Pool</Nav.Link>*/}
                <Nav.Link onClick={() => setShowReferModal(true)} style={{ color: themeMode.onSurface }}>Invite</Nav.Link>
                <Nav.Link as={Link} to={"user/" + userSlug} style={{ color: themeMode.onSurface }}>Profile</Nav.Link>
                <Nav.Link as={Link} to="faqs" style={{ color: themeMode.onSurface }}>FAQs</Nav.Link>
                <Nav.Link onClick={logOutNavigate} style={{ color: themeMode.onSurface }}>Logout</Nav.Link>
            </Navbar.Collapse></>}
        </StyledNavbar>
    );
}

export default NavbarSmallScreen;


