import { useState, useEffect } from "react";
import { useTheme } from "styled-components";
import { myAxiosInstance } from "./axiosConfig";
import { StyledDropdown, StyledH5, StyledButton } from "./StyledBootstrap";
import { Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { useErrorBoundary } from "react-error-boundary";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";
import { timeSince } from "./CriticalFunctions";




function NotificationsSmall() {
    const themeMode = useTheme();
    const {showBoundary} = useErrorBoundary();
    const pollingInterval = 30000;
    const [notifications, setNotifications] = useState([]);
    const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
    const [shouldFetchNotifications, setShouldFetchNotifications] = useState(true);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const notificationCountStyle = {
        position: 'absolute',
        top: '0.25rem',
        right: '0.25rem',
        padding: '0rem 0rem',
        borderRadius: '50%',
        background: themeMode.secondary,
        height: '1.5rem',
        width: '1.5rem',
        color: themeMode.onSecondary,
        fontSize: '1rem',
        fontWeight: 'bold',

        // Flexbox properties to center content
        display: 'flex',
        alignItems: 'center',  // Vertical alignment
        justifyContent: 'center', // Horizontal alignment
    };


    const fetchNotifications = async () => {

        try {
            const response = await myAxiosInstance.get('/api/notifications/');
            setNotifications(response.data);
            setUnreadNotificationsCount(response.data.filter(notification => !notification.read).length)

        } catch (error) {
            showBoundary(error);
        }


    };


    useEffect(() => {
        // Function to fetch notifications


        // Initial fetch
        fetchNotifications();

        // Set up polling with setInterval
        const intervalId = setInterval(fetchNotifications, pollingInterval);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [shouldFetchNotifications]);



    const handleNotificationClick = async (notificationId) => {
        try {
            // Call the API to mark the notification as read
            await myAxiosInstance.post(`/api/notifications/mark-read/${notificationId}/`);

            // Update the state to reflect the read status of the notification
            setNotifications(notifications.map(notification =>
                notification.pk === notificationId
                    ? { ...notification, read: true }
                    : notification
            ));
            fetchNotifications();
        } catch (error) {}
    };


    const handleMarkAllAsRead = async () => {
        try {
            // Call the API to mark the notification as read
            await myAxiosInstance.post("/api/read-notifications/");

            // Update the state to reflect the read status of the notification
            setNotifications(notifications.map(notification =>

                ({ ...notification, read: true })

            ));
            fetchNotifications();
        } catch (error) {}
    };


    const handleNotificationDropdownToggle = async (isOpen) => {
        setDropdownOpen(isOpen);
        setShouldFetchNotifications(!isOpen);
    };



    return (
        <StyledDropdown onToggle={handleNotificationDropdownToggle} show={dropdownOpen} className='d-flex flex-column justify-content-center align-items-center'>
            <StyledDropdown.Toggle as={Nav.Link} className='no-caret-icon'>
                <FontAwesomeIcon icon={faBell} fontSize={'1.9rem'} />
                {unreadNotificationsCount > 0 && (
                    <span style={notificationCountStyle}>{
                        unreadNotificationsCount
                    }</span>
                )}
            </StyledDropdown.Toggle>
            <StyledDropdown.Menu align={'end'} style={{ maxWidth: '19rem', overflow: 'hidden', maxHeight: '40rem', overflowY: 'auto' }}>
                <>
                    <div className='d-flex justify-content-between align-items-center' style={{ textAlign: 'center', width: '100%' }}><StyledH5 className='ml-3'>Notifications</StyledH5><StyledButton onClick={handleMarkAllAsRead} size="sm" className='mr-1' inline>Mark All As Read</StyledButton></div>
                    {notifications.length === 0 ? (
                        <StyledDropdown.Item>No notifications</StyledDropdown.Item>
                    ) : (
                        notifications.map((notification, index) => (
                            <OverlayTrigger
                                key={index}
                                placement="left"
                                overlay={
                                    <Tooltip id={`tooltip-${index}`}>
                                        {notification.message}
                                    </Tooltip>
                                }
                            >
                                <StyledDropdown.Item key={index} as={Link} to={"/" + notification.redirect_link} onClick={() => handleNotificationClick(notification.pk)} className={notification.read ? '' : 'unread-notification'} title={notification.message}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '10px' }}>
                                        <div style={{ flex: '1 1 auto', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                            {notification.message}
                                        </div>
                                        <div style={{ flex: '0 0 auto', fontSize: '0.75rem', color: '#007bff', whiteSpace: 'nowrap' }}>
                                            {timeSince(notification.created_at)}
                                        </div>
                                    </div>
                                </StyledDropdown.Item>
                            </OverlayTrigger>
                        ))
                    )}</>
            </StyledDropdown.Menu>
        </StyledDropdown>);

}

export default NotificationsSmall;