import Loading from "./Loading";
import { useErrorBoundary } from "react-error-boundary";
import UserPreviewCard from "./UserPreviewCard";
import { myAxiosInstance } from "./axiosConfig";
import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { StyledCard, StyledH5 } from "./StyledBootstrap";
import { useTheme } from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useAuth } from "./AuthContext";


const PortfolioPage = () => {


    const [isBottomLoading, setIsBottomLoading] = useState(true);
    const { portfolioSize } = useAuth();
    const [portfolio, setPortfolio] = useState([]);
    const [suggestedUserArray, setSuggestedUserArray] = useState([]);
    const [bottomReload, setBottomReload] = useState(0);
    const themeMode = useTheme();
    const { showBoundary } = useErrorBoundary();
    const isMdOrLarger = useMediaQuery({ minWidth: 768 });



    useEffect(() => {
        const getPortfolio = async () => {
            try {
                setIsBottomLoading(true);

                const response = await myAxiosInstance.get('/api/user-portfolio/');
                setPortfolio(response.data.portfolio);
                setSuggestedUserArray(response.data.daily_picks);


            } catch (error) {
                showBoundary(error);
            } finally {
                setIsBottomLoading(false);
            }
        };

        getPortfolio();


    }, [bottomReload]);


    return (
        isBottomLoading ? <Loading />
            :
            <div className='d-flex justify-content-center' style={{ backgroundColor: themeMode.background, minWidth: 0, width: '100%', minHeight: 0, height: '100%'  }}>
                <div className='home-joyrride mx-2' style={{ minWidth: 0, width: '100%', minHeight: 0, height: '100%' }}>

                    <div><StyledH5 className="mt-4"><strong>Portfolio Size: {portfolioSize}</strong></StyledH5></div>

                    <StyledCard>
                        <StyledCard.Body>


                            
                            <div className="d-flex flex-wrap justify-content-center">
                                {portfolio.length > 0 ? portfolio.map((member, index) => (
                                    <div className="mx-1 my-1">
                                        <UserPreviewCard preview_object={member}></UserPreviewCard>
                                    </div>
                                )) : <div>Discover and meet other students from across the country. If someone catches your eye, send them a 10-minute meeting request. Once you’ve connected, you’ll be added to each other’s portfolios. Think of the Prospinity Portfolio as your personal collection of meaningful contacts you can always access right away whenever you need something or propose to join a pool together.</div>}
                            </div>
                        </StyledCard.Body>
                    </StyledCard>
                </div>
                {/*isMdOrLarger && (
                    <div className="mx-2">
                        <StyledH5 className="mt-4"><strong>Daily Picks</strong></StyledH5>

                        <StyledCard style={{ height: '80vh', overflowY: 'auto' }}>
                            <StyledCard.Body>
                                {suggestedUserArray
                                    .filter(otherUserInfo => otherUserInfo.first_name !== '')
                                    .reduce((acc, curr, index) => {
                                        if (index % 2 === 0) {
                                            // Every even index, create a new row with two elements
                                            acc.push([curr]);
                                        } else {
                                            // Add the current item to the last row
                                            acc[acc.length - 1].push(curr);
                                        }
                                        return acc;
                                    }, [])
                                    .map((pair, pairIndex) => (
                                        <div className="d-flex align-items-center justify-content-center" key={pairIndex}>
                                            {pair.map((otherUserInfo, index) => (
                                                <div className="mx-1 my-2" key={index}>
                                                    <UserPreviewCard preview_object={otherUserInfo} buttonBoolean={true} bottomReload={bottomReload} setBottomReload={setBottomReload} />
                                                </div>
                                            ))}
                                        </div>
                                    ))}

                            </StyledCard.Body>
                        </StyledCard>
                    </div>


                )*/}
            </div>

    )

}



export default PortfolioPage;