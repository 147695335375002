import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import logo from './Images/Logo1.png';
import { useAuth } from './AuthContext';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button, Row, Col, Container, InputGroup, Dropdown, Card } from 'react-bootstrap';
import { StyledCard, StyledH4, StyledFormControl, StyledForm, StyledFormGroup, StyledFormLabel, StyledButton, StyledParagraph } from './StyledBootstrap';
import { useTheme } from 'styled-components';
import { myAxiosInstance } from './axiosConfig';
import logo_gradient from './Images/logo_gradient.png';
import logo_white from './Images/logo_white.png';
import logo_black from './Images/logo_black.png';
import logo_navy from './Images/logo_navy.png';
import { errorString } from './CriticalFunctions';





function SignIn() {

    const themeMode = useTheme();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { handleLogin } = useAuth();


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [errors, setErrors] = useState({ email: false, password: false, non_field_errors: false });


    const firstInputRef = useRef(null);
    useEffect(() => {
        // Focus the appropriate input field based on the step
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, []);

    async function authenticateUser(credentials, setIsLoading, setError, aboveLogin) {
        setIsLoading(true);
        setError(null);

        try {
            const response = await axios.post('/prospinity-auth/login/', credentials);
            aboveLogin(response);
        }
        catch (error) {

            if (error.response && error.response.data) {
                // Construct the error list JSX and pass it to setMessage
                let tempErrors = { password: false, email: false, non_field_errors: false }
                for (const [key, value] of Object.entries(error.response.data)) {
                    tempErrors = { ...tempErrors, [key]: true };
                }
                setErrors(tempErrors);

                if (tempErrors.non_field_errors) {
                    setError(error.response.data.non_field_errors);
                }

            }
            else {
                setError("Unable to login with provided credentials.");
            }

        }
        finally {
            setIsLoading(false);
        }
    }

    const handleSignIn = (credentials) => {
        authenticateUser(credentials, setIsLoading, setError, handleLogin);
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        handleSignIn({ 'email': email, 'password': password });
    };




    return (
        <>
            <div>
                <Container fluid style={{ height: '100vh', backgroundColor: themeMode.background }} className='d-flex flex-column justify-content-center'>
                    <Row className='justify-content-center'>
                        <Col className='col-lg-4 col-md-6'>

                            <StyledCard>
                                <StyledCard.Body>
                                    <Row className='justify-content-center mb-4'>
                                        <Col md={10} className="text-center">
                                            <img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="40rem" />

                                        </Col>
                                    </Row>
                                    <>
                                        <StyledForm onSubmit={handleSubmit}>
                                            <StyledFormGroup controlId="formBasicEmail" className='mb-4'>

                                                <StyledFormControl
                                                    type="email"
                                                    placeholder="Email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    ref={firstInputRef}
                                                    hasError={errors.email}
                                                    onClick={() => setErrors({ ...errors, email: false })}
                                                />
                                                {errors.email ? <StyledParagraph className='mt-1' style={{ fontSize: '0.7rem', color: themeMode.error }}>Please enter a valid email.</StyledParagraph> : <></>}


                                            </StyledFormGroup>
                                            <StyledFormGroup controlId="formBasicPassword">

                                                <StyledFormControl
                                                    type="password"
                                                    placeholder="Password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    hasError={errors.password}
                                                    onClick={() => setErrors({ ...errors, password: false })}
                                                />
                                                {errors.password ? <StyledParagraph className='mt-1' style={{ fontSize: '0.7rem', color: themeMode.error }}>Please enter a valid password.</StyledParagraph> : <></>}

                                            </StyledFormGroup>
                                            <Row className='justify-content-center mt-1'>
                                                <Col md={6}>
                                                    <StyledButton variant="primary" type="submit" className="w-100">
                                                        Log In
                                                    </StyledButton>

                                                </Col>
                                            </Row>

                                        </StyledForm>
                                    </>
                                    <div className='text-center mt-2'>
                                        {isLoading && <StyledParagraph>Signing in...</StyledParagraph>}
                                        {error && <StyledParagraph>{error}</StyledParagraph>}
                                        <StyledParagraph className='mt-3'> Received an invite? <a href="/signup"> Create Your Account.</a></StyledParagraph>
                                        <StyledParagraph>Forgot Password? <a href="/password-reset-email">Get Reset Link.</a></StyledParagraph>
                                    </div>
                                </StyledCard.Body>
                            </StyledCard>
                        </Col>
                    </Row>
                </Container>


            </div>
        </>
    );
}



export default SignIn;
