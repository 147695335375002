import React from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import logoBlack from './Images/logo_black.png';

export default function SideBar() {
    const navigate = useNavigate();
    const location = useLocation();

    const links = [
        { label: 'Home', to: '/dashboard/home', icon: '🏠' },
        { label: 'Pools', to: '/dashboard/pools', icon: '👥' },
        { label: 'People', to: '/dashboard/people', icon: '📚' },
        { label: 'Inbox', to: '/dashboard/inbox', icon: '✉️' },
        { label: 'Finances', to: '/dashboard/finances', icon: '💰' },
    ];

    return (
        <div
            style={{
                width: '260px',
                height: '100vh',
                backgroundColor: '#FFFFFF',
                display: 'flex',
                flexDirection: 'column',
                padding: '1.5rem',
                borderRight: '1px solid rgba(170, 170, 183, 0.4)', // Vertical line
                position: 'fixed', // Fixed positioning
                top: 0,
                left: 0,
                zIndex: 1000, // Ensure it stays above other elements
            }}
        >
            {/* Logo */}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '2.3rem',
                }}
            >
                <Link to="/dashboard/home">
                    <img
                        src={logoBlack} // Replace this with the actual logo import or URL
                        alt="Prospinity Logo"
                        style={{
                            width: '150px',
                            height: 'auto',
                            cursor: 'pointer',
                        }}
                    />
                </Link>
            </div>
            {/* Navigation Links */}
            {links.map((link) => (
                <SideBarLink
                    key={link.to}
                    label={link.label}
                    to={link.to}
                    active={
                        link.to === '/dashboard/home'
                            ? location.pathname === '/dashboard' ||
                              location.pathname === '/dashboard/home'
                            : location.pathname === link.to
                    }
                    onClick={() => navigate(link.to)}
                >
                    <div style={{ marginRight: '1rem' }}>{link.icon}</div>
                </SideBarLink>
            ))}
        </div>
    );
}

const SideBarLink = ({ children, label, to, active, onClick }) => {
    return (
        <div
            className="nav-item d-flex align-items-center justify-content-start py-2 mb-2"
            style={{
                borderRadius: '1.5rem',
                padding: '0.7rem 0.9375rem',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease, color 0.3s ease',
                color: active ? '#000000' : '#717173', // Black when active, grey otherwise
                backgroundColor: active ? '#F0F1F4' : 'transparent', // Highlight background for active
            }}
            onMouseEnter={(e) => {
                if (!active) {
                    e.currentTarget.style.backgroundColor = '#F0F1F4';
                }
            }}
            onMouseLeave={(e) => {
                if (!active) {
                    e.currentTarget.style.backgroundColor = 'transparent';
                }
            }}
            onClick={onClick}
        >
            {children}
            <span>{label}</span>
        </div>
    );
};

export { SideBarLink };
