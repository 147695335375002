import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { Nav, FormControl, Row, Col, Container, InputGroup, Dropdown, Navbar, Button } from 'react-bootstrap';
import logo from './Images/logo3.png';
import logo1 from './Images/Logo1.png';
import logo_gradient from './Images/logo_gradient.png';
import logo_white from './Images/logo_white.png';
import logo_black from './Images/logo_black.png';
import logo_navy from './Images/logo_navy.png';
import { useAuth } from './AuthContext';
import './Dashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // If using Font Awesome for icons
import SearchUsers from './SearchUsers';
import { faHome, faHandshake, faQuestion, faUser, faSwimmer, faFileInvoiceDollar, faSignOut, faChartLine, faCreditCard, faCheck, faSearch, faBell, faPlus, faGear, faChevronDown } from '@fortawesome/free-solid-svg-icons'; // Example icons
import { useMediaQuery } from 'react-responsive';
import { ProfilePicture } from './MiscComponents';
import { timeSince } from './CriticalFunctions';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useErrorBoundary } from "react-error-boundary";
import { StyledNavbar, StyledDropdown, StyledButton, StyledH5, StyledNavLink } from './StyledBootstrap';
import { useTheme } from 'styled-components';
import { myAxiosInstance } from './axiosConfig';
import Notifications from './Notifications';
import React from 'react';

import './NavbarLargeScreenStyle.css';

export default function NavbarLargeScreen() {
    return (
        <div className="navbar-container">
            {/* Search Bar */}
            <div className="navbar-search">
                <FontAwesomeIcon
                    icon={faSearch}
                    style={{ color: "#717173", fontSize: "1.3rem", marginRight: "0.8rem", lineHeight: "1.5rem" }}
                />
                <input
                    type="text"
                    placeholder="Search users"
                    className="search-input"
                />
            </div>

            {/* Right Section */}
            <div className="navbar-right">
                {/* Notifications */}
                <div className="navbar-notification">
                    <div className="notification-icon-wrapper">
                        <FontAwesomeIcon icon={faBell} className="notification-icon" />
                        <div className="notification-dot"></div>
                    </div>
                </div>

                {/* User Profile */}
                <ProfileWithPopup />
            </div>
        </div>
    );
}

function ProfileWithPopup() {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const popupRef = useRef(null);

    const togglePopup = () => setIsOpen((prev) => !prev);

    const closePopup = () => setIsOpen(false);

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            closePopup();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <div style={{ position: 'relative' }} ref={popupRef}>
            {/* Profile Trigger */}
            <div className="navbar-profile" onClick={togglePopup}>
                <div className="profile-picture-wrapper">
                    <img
                        src="https://images.prospinity.com/profile_pictures/main_profile_picture_sam-antonyan-lj42_20250117025949.webp"
                        alt="User Profile"
                        className="profile-picture"
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', }} >
                    <span className='profile-name'>John B.</span>
                    <FontAwesomeIcon icon={faChevronDown} className="profile-arrow" />
                </div>
            </div>

            {/* Popup */}
            {isOpen && (
                <div
                    style={{
                        position: 'absolute',
                        top: '52px', // Adjusted to be further below
                        right: 0,
                        width: '230px', // Match the sidebar width
                        backgroundColor: '#FFFFFF',
                        borderRadius: '1.5rem',
                        zIndex: 1000,
                        padding: '1rem',
                        border: '1px solid rgba(170, 170, 183, 0.4)',
                    }}
                >
                    {/* Links */}
                    <PopupLink label="Profile" icon={faUser} onClick={() => { navigate('user/john-b'); closePopup(); }} />
                    <PopupLink label="Settings" icon={faGear} onClick={() => { navigate('settings'); closePopup(); }} />
                    <PopupLink label="FAQs" icon={faQuestion} onClick={() => { navigate('faqs'); closePopup(); }} />
                    <PopupLink label="Logout" icon={faSignOut} onClick={() => { console.log('Logging out'); closePopup(); }} />
                </div>
            )}
        </div>
    );
}

const PopupLink = ({ label, icon, onClick }) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            padding: '0.7rem 0.9375rem',
            borderRadius: '1.5rem',
            cursor: 'pointer',
            color: '#717173',
            transition: 'background-color 0.3s ease',
        }}
        onClick={onClick}
        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#F0F1F4')}
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
    >
        <FontAwesomeIcon icon={icon} style={{ marginRight: '1rem' }} />
        <span>{label}</span>
    </div>
);



// function NavbarLargeScreen({ setShowReferModal, setWhyModalShow }) {
//     const themeMode = useTheme();
//     const navigate = useNavigate();
//     const { userSlug, handleLogout, userFirstName, userLastName, userGraduationDate, profilePictureLink } = useAuth();

//     const logOutNavigate = () => {
//         handleLogout();
//         navigate('/signing-out');

//     }

//     const getBaseFontSize = () => {
//         // Get the font-size of the body element
//         const fontSize = window.getComputedStyle(document.body).fontSize;
//         // Parse the font size and return it as a number
//         return parseFloat(fontSize);
//     };



//     return (
//         <StyledNavbar expand="sm" sticky='top'>
//             <Container fluid>
//                 <div className='w-100 d-flex justify-content-between'>
                    
//                         <Navbar.Brand className="d-flex ml-2 align-items-center" onClick={() => navigate('/home')} style={{ cursor: 'pointer' }}>
//                             <img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="40rem" className="d-inline-block align-top" alt="Logo" />
//                         </Navbar.Brand>
                    

//                     <div className='w-50'>
//                         <SearchUsers />
//                     </div>

//                     <div className="d-flex align-items-center">

//                         {/*<StyledButton onClick={() => setWhyModalShow(true)} style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", height: "60%", marginRight: "0.5em", paddingInline: "0.8em", backgroundColor: themeMode.theme==='dark' ? themeMode.onSurface : themeMode.primary, color: themeMode.theme==='dark' ? themeMode.surface : themeMode.onPrimary}}>Why?</StyledButton>*/}
//                         {/*<StyledButton className='invite-joyrride' onClick={() => setShowReferModal(true)} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", height: "60%", marginRight: "0.5em", paddingInline: "0.8em", backgroundColor: themeMode.theme === 'dark' ? themeMode.onSurface : themeMode.primary, color: themeMode.theme === 'dark' ? themeMode.surface : themeMode.onPrimary }}>Invite</StyledButton>*/}
//                         <Notifications />
//                         <StyledDropdown >
//                             <StyledDropdown.Toggle as={Nav.Link} className="no-caret-icon profile-dropdown">
//                                 {/*<FontAwesomeIcon icon={faUser} />*/}
//                                 <div className='profile-dropdown-joyrride'>
//                                     <ProfilePicture name={userFirstName + " " + userLastName} size={getBaseFontSize() * 3} graduation_date={userGraduationDate} picture_link={profilePictureLink} />
//                                 </div>
//                             </StyledDropdown.Toggle>
//                             <StyledDropdown.Menu align={'end'}>
//                                 <StyledDropdown.Item as={Link} to={"user/" + userSlug} >
//                                     <Row>
//                                         <Col xs={2}><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></Col>
//                                         <Col>Profile</Col>

//                                     </Row>
//                                 </StyledDropdown.Item>

//                                 <StyledDropdown.Item as={Link} to="settings">
//                                     <Row>
//                                         <Col xs={2}><FontAwesomeIcon icon={faGear}></FontAwesomeIcon></Col>
//                                         <Col>Settings</Col>

//                                     </Row>
//                                 </StyledDropdown.Item>

//                                 <StyledDropdown.Item as={Link} to="faqs">
//                                     <Row className='faqs-joyrride'>
//                                         <Col xs={2}><FontAwesomeIcon icon={faQuestion}></FontAwesomeIcon></Col>
//                                         <Col>FAQs</Col>

//                                     </Row>
//                                 </StyledDropdown.Item>
//                                 <StyledDropdown.Item onClick={logOutNavigate}>
//                                     <Row>
//                                         <Col xs={2}><FontAwesomeIcon icon={faSignOut}></FontAwesomeIcon></Col>
//                                         <Col>Logout</Col>

//                                     </Row>
//                                 </StyledDropdown.Item>
//                             </StyledDropdown.Menu>
//                         </StyledDropdown>
//                     </div>
//                 </div>
//             </Container>

//         </StyledNavbar>);
// }
