import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import { SurveyInput } from "./ApplicationComponents";
import WhiteLogo from "../Images/logo_white.png"; // Import your logo here

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000; /* Pure black background */
  color: white;
  overflow: hidden;
  font-family: "Playfair Display", serif; /* Premium font for elegance */
`;



const Logo = styled.img`
  width: ${({ isMdOrLarger }) => (isMdOrLarger ? "20rem" : "13rem")};
  margin-bottom: 1rem;
`;

const ApplicationText = styled(motion.div)`
  font-size: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.7rem" : "1.2rem")};
  line-height: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.6" : "1.2")};
  text-align: center;
  color: rgba(255, 255, 255, 0.9); /* Slightly softer white */
  padding: 1rem;
`;

const NavigationButtons = styled.div`
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  font-size: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.4rem" : "1.2rem")};
  font-family: "Playfair Display", serif; /* Premium font for elegance */
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;

  span {
    transition: color 0.2s ease;
  }

  span:hover {
    color: white;
  }
`;

export default function ApplicationQuestions({ navigateToIntro, navigateToApplication }) {
  const [fadeOut, setFadeOut] = useState(false); // For fading out the page
  const isMdOrLarger = useMediaQuery({ minWidth: 768 });

  const handleNavigateToIntroPage = () => {
    setFadeOut(true); // Trigger fade-out animation
    setTimeout(() => {
        navigateToIntro(); // Navigate only after fade-out finishes
    }, 1500); // Match the fade-out duration
  };

  return (
    <PageContainer
      as={motion.div}
      initial={{ opacity: 1, y: 0 }}
      animate={fadeOut ? { opacity: 0 } : { opacity: 1 }}
      transition={{ duration: 1.5 }} // Smooth fade-out transition
    >
      <>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }} // Smooth fade-in for logo and text
          style={{ textAlign: "center" }}
        >
          <Logo
            src={WhiteLogo}
            alt="Prospinity Logo"
            isMdOrLarger={isMdOrLarger}
          />
          <ApplicationText isMdOrLarger={isMdOrLarger}>
            <p>What's your name?</p>
            <SurveyInput
                type="text"
                placeholder={"Type your answer here..."} // Customizable placeholder
                // defaultValue={inputRef.current} // Browser manages this value
                autoFocus={true} // Automatically focus the input by default
                isMdOrLarger={isMdOrLarger}
                // onChange={(e) => handleInput(e.target.value)} // Update parent state on change
                />
          </ApplicationText>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }} // Smooth fade-in for buttons
          style={{ textAlign: "center" }}
        >
          <NavigationButtons isMdOrLarger={isMdOrLarger}>
            <span onClick={handleNavigateToIntroPage}>← exit</span>
            <span onClick={() => console.log("start now")}>next →</span>
          </NavigationButtons>
        </motion.div>
      </>
    </PageContainer>
  );
}
